@import '../../Core/variables.scss';

.#{$uikitName}__profile {
  position: relative;
  padding: 10px 26px;

  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  flex-wrap: wrap;

  &:hover & {
    &__submenu {
      display: block;
    }
  }

  &__avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #cfd6dc;
    border: 1px solid #a7aeb4;
  }

  &__username {
    font-size: 14px;
    font-weight: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    &-initials {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-image: url('../Header/user.svg');
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 12px;
    }
  }

  &__submenu {
    display: none;
    position: absolute;
    right: 0;
    padding: 0;
    min-width: 200px;
    background: $background-white;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    box-shadow: 0 4px 16px 0 rgba(43, 43, 44, 0.16), 0 1px 4px 0 rgba(27, 57, 133, 0.08);
    margin-top: 14px;

    &__item {
      border-bottom: 1px solid $verylightblue-light;

      &:before {
        display: none;
        content: none;
      }

      &:last-child {
        border-bottom: none;
      }

      a {
        color: $grey-dark;
        display: block;
        text-decoration: none;
        padding: $spaceSm $spaceMd;

        &:hover {
          color: $blue;
        }
      }
    }
  }
}
