@import '../Core/variables';

.emankit__tabs {
  &__menu {
    position: relative;
    margin: 10px 0 10px;
    display: flex;
    list-style-type: none;
    padding: 0;

    &:before {
      content: "";
      z-index: -1;
      left: 0;
      bottom: 0;
      position: absolute;
      width: 100%;
    }

    &__item {
      cursor: pointer;
      padding-bottom: 4px;
      margin-right: 40px;
      text-transform: uppercase;
      font-weight: bold;
      color: $fontFieldsetColor;

      &:before {
        content: none;
      }

       &--active {
         border-bottom: 2px solid $green;
         color: $darkGray;
       }
    }
  }

  &__content {
    padding: 15px 0;
  }
}
