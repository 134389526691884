@import '../Core/variables';
@import '../Core/functions';

.sub_header {
  background-color: rgba(167, 174, 180, 0.07);
}

th {
  .emankit__filter_bar__item {
    text-align: left;
    display: inline-block;
    margin-left: 0;

    .inputs {
      label {
        display: block;
        width: 100%;
      }
    }

    .dropdown {
      margin-top: 13px;

      .date_container {
        padding: 8px;

        .DayPickerInput {
          margin-top: 5px;
        }

        label {
          font-weight: bold;
        }
      }
    }

    .rectangle {
      cursor: pointer;
      width: 24px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      display: inline-block;
      border: solid 1px #c1c4c6;
      margin-left: 12px;

      &.selected {
        svg {
          fill: $white;
        }
        background: $green;
        border-color: $green;
      }

      .emankit__icon {
        vertical-align: baseline;
      }
    }
  }
}

.emankit__button.export_btn {
  margin-left: 5px;
}

.emankit__data_table_container {
  align-self: center;
  margin-bottom: 24px;

  .emankit__data_table_filters {
    margin-bottom: 20px;
  }

  .DayPickerInput-Overlay,
  .tippy-popper {
    z-index: 1000 !important;
  }

  .document-filter {
    display: inline-block;
    margin: 1em;
  }
}

.sticky {
  @extend %below-fixed-header;
  position: -webkit-sticky;
  position: sticky;
  z-index: 10;

  &:before, &:after {
    content: '';
    display: table;
  }
}

.__checkbox {
  text-align: center !important;
}

.emankit__data_table {
  min-width: 100%;
  max-width: calc(100vw - 30px);
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);

  @media (min-width: 1440px) {
    max-width: calc(100vw - 60px);
  }

  // only Header table
  .sticky > & {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    user-select: none;

    thead th {
      border-top-width: 0;
      border-bottom-width: 0;
      padding: 11px 10px;
      white-space: nowrap;
    }
  }

  // only Content table
  .sticky + & {
    border-bottom: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    tbody tr:last-child {
      td:first-child {
        border-bottom-left-radius: 4px;
      }

      td:last-child {
        border-bottom-right-radius: 4px;
      }
    }
  }

  thead {

    th {
      position: relative;
      height: 56px;

      .body_header {
        display: flex;
        flex-direction: row;

        .sort_label {
          flex: 1;
          flex-wrap: nowrap;
          flex-flow: row;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          align-items: center;
          min-width: 0 !important;
        }

        .emankit__data_table_filter_item {
          margin-left: 10px;
        }
      }

      &.sortable {
        cursor: pointer;

        label {
          font-weight: normal;
        }

        &:last-child {
          .dropdown {
            right: 0;
          }
        }

        // Sort
        .sort_indicator {
          margin-right: 8px;
          margin-top: -4px;
          flex-shrink: 0;

          path {
            fill: $silver;
          }
        }

        &.sorted .sort_indicator {
          path {
            fill: $black;
          }
        }
      }
    }
  }

  td, th {
    border-left: none !important;
    border-right: none !important;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;

    &:first-child {
      border-left: 1px solid transparent !important;
    }

    &:last-child {
      border-right: 1px solid transparent !important;
    }
  }

  @media (max-width: 1023px) {
    tbody, thead {
      th:nth-child(3), td:nth-child(3) {
        padding-left: 0;
      }
    }
  }

  td.action {
    white-space: nowrap;

    & > div[data-tooltipped],
    & > button,
    & > .button {
      margin-right: 10px;
    }
  }

  tr.selected {
    td {
      background-color: $lightGreen;
    }

    & + tr.selected {
      td {
        border-top: 1px solid transparent;
      }
    }
  }

  tr:nth-child(even) {
    .without_checkbox {
      width: 25px;
    }
  }

  td.children_opener > button {
    width: 137px;
  }

  .child_icon {
    margin-right: 8px;
  }

  .respect__input_checkbox {
    margin-top: 2px;
    margin-bottom: 1px
  }
}
