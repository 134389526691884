@import "../Core/variables";

.emankit__intervalPicker {
  position: relative;
  display: inline-block;
  line-height: 20px;
  width: 100%;
}

.emankit__intervalPicker, .emankit__dateIntervalInputPicker {
  &:focus, & *:focus {
    outline: none;
  }

  .DayPicker {
    .DayPicker-Month:first-child {
      margin-right: 8px !important;
    }
    .DayPicker-Month:last-child {
      margin-left: 8px !important;
    }
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    opacity: 0;
    display: none;

    &--visible {
      display: block;
    }
  }

  &__calendar {
    position: absolute;
    top: 40px;
    z-index: 3;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 4px 16px 0 rgba(43, 43, 44, 0.16), 0 1px 4px 0 rgba(27, 57, 133, 0.08) !important;
    padding: 16px;

    &--1 {
      width: 326px;
    }

    &--2 {
      width: 610px;
    }

    &--3 {
      width: 920px;
    }

    &.DayPicker {
      display: none;
    }

    &--visible.DayPicker{
      display: block;
    }
  }

  .DayPicker-Week {

    /* SELECTED START + STOP - BEGIN */
    .DayPicker-Day--start.DayPicker-Day--selected:not(.DayPicker-Day--outside) + .DayPicker-Day--selected,
    .DayPicker-Day--selected:not(.DayPicker-Day--outside) + .DayPicker-Day--end.DayPicker-Day--selected,
    .DayPicker-Day--start.DayPicker-Day--selected:not(.DayPicker-Day--outside):last-child,
    .DayPicker-Day--end.DayPicker-Day--selected:not(.DayPicker-Day--outside):first-child,
    .DayPicker-Day--selected.DayPicker-Day--outside + .DayPicker-Day--end:not(.DayPicker-Day--outside),
    .DayPicker-Day--start.DayPicker-Day--selected:not(.DayPicker-Day--outside) + .DayPicker-Day--end.DayPicker-Day--selected:not(.DayPicker-Day--outside)
    {
      position: relative;
    }
    .DayPicker-Day--start.DayPicker-Day--selected:not(.DayPicker-Day--outside) + .DayPicker-Day--selected:before,
    .DayPicker-Day--selected:not(.DayPicker-Day--outside) + .DayPicker-Day--end.DayPicker-Day--selected:before,
    .DayPicker-Day--start.DayPicker-Day--selected:not(.DayPicker-Day--outside):last-child:after,
    .DayPicker-Day--end.DayPicker-Day--selected:not(.DayPicker-Day--outside):first-child:after,
    .DayPicker-Day--selected.DayPicker-Day--outside + .DayPicker-Day--end:not(.DayPicker-Day--outside):before,
    .DayPicker-Day--start.DayPicker-Day--selected:not(.DayPicker-Day--outside) + .DayPicker-Day--end.DayPicker-Day--selected:not(.DayPicker-Day--outside):before
    {
      content: '';
      display: block;
      width:40px;
      height:40px;
      position: absolute;
      top: 0;
      z-index: -1;
    }

    /* SELECTED START + MONTH END IN BETWEEN */
    .DayPicker-Day--start.DayPicker-Day--selected:not(.DayPicker-Day--outside) + .DayPicker-Day--selected:before {
      left: -40px;
      border-bottom-left-radius: 20px;
      border-top-left-radius: 20px;
      background-color: $lightGreen;
    }

    .DayPicker-Day--selected + .DayPicker-Day--end.DayPicker-Day--selected:not(.DayPicker-Day--outside):before {
      left: 0;
      border-bottom-right-radius: 20px;
      border-top-right-radius: 20px;
      background-color: $lightGreen;
    }

    /* SELECTED START WEEK END + END WEEK BEGIN */
    .DayPicker-Day--start.DayPicker-Day--selected:last-child:after, .DayPicker-Day--end.DayPicker-Day--selected:first-child:after {
      background-color: $lightGreen;
    }
    .DayPicker-Day--end.DayPicker-Day--selected:first-child:after {
      border-bottom-right-radius: 20px;
      border-top-right-radius: 20px;
    }
    .DayPicker-Day--start.DayPicker-Day--selected:last-child:after {
      border-bottom-left-radius: 20px;
      border-top-left-radius: 20px;
    }

    /* START + STOP IN SAME DAY - NO OVERFLOW */
    .DayPicker-Day--start.DayPicker-Day--end:after {
      border-bottom-left-radius: 20px;
      border-top-left-radius: 20px;
      border-bottom-right-radius: 0 !important;
      border-top-right-radius: 0 !important;
    }

    /* SELECTED START + END NEXT EACH OTHER */
    .DayPicker-Day--start.DayPicker-Day--selected:not(.DayPicker-Day--outside) + .DayPicker-Day--end.DayPicker-Day--selected:not(.DayPicker-Day--outside):before {
      left: -20px;
      border-radius: 0;
    }
    /* SELECTED START + STOP - END */


    // * Nasledujici kod zustal zakomentovany z toho duvodu, ze pokud bude dale v projektu cas, vratim se k tomu a dodelam to presne dle graf. navrhu - jedna se o border radius u zacatku/konce mesice, pokud vybrana range zasahuje do vice nez 1 mesice... * //

    ///* ROUNDING FIRST SELECTED DAY IN A MONTH */
    //.DayPicker-Day--selected.DayPicker-Day--outside:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--disabled) + .DayPicker-Day--selected {
    //  border-bottom-left-radius: 20px !important;
    //  border-top-left-radius: 20px !important;
    //}

    ///* ROUNDING LAST SELECTED DAY IN A MONTH */
    //.DayPicker-Day--selected:not(.DayPicker-Day--outside) + .DayPicker-Day--outside {
    //  position: relative;
    //}
    //.DayPicker-Day--selected:not(.DayPicker-Day--outside) + .DayPicker-Day--outside:before {
    //  content: '';
    //  display: block;
    //  width:40px;
    //  height:40px;
    //  position: absolute;
    //  top: 0;
    //  z-index: -1;
    //
    //  left: -40px;
    //  border-bottom-right-radius: 20px;
    //  border-top-right-radius: 20px;
    //  background-color: red;
    //  //background-color: $lightGreen;
    //}

    .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside):not(.DayPicker-Day--disabled) {
      background-color: $lightGreen;
      border-radius: 0 !important;
      color: $black !important;
    }

    .DayPicker-Day--selected.DayPicker-Day--outside:not(.DayPicker-Day--disabled) {
      cursor: pointer;
    }

    .DayPicker-Day--start:not(.DayPicker-Day--outside):not(.DayPicker-Day--disabled), .DayPicker-Day--end:not(.DayPicker-Day--outside):not(.DayPicker-Day--disabled) {
      background-color: $green;
      color: $white;
    }
  }

}
