@import '../Core/variables.scss';
@import '../Core/mixins.scss';

@import './Profile/style.scss';

.#{$uikitName}__header {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: $white;
  align-items: center;
  z-index: 800; /* Modal overlay got z-index 999 ... */
  height: auto;
  min-height: $headerHeight;
  display: flex;
  @include calc-size(padding-left, $spaceMd, $spaceLg, $mdBreakpoint, $xxlBreakpoint);
  @include calc-size(padding-right, $spaceMd, $spaceLg, $mdBreakpoint, $xxlBreakpoint);
  border-bottom: 1px solid $rowLineColor;
  box-shadow: 0 1px 4px 0 rgba(27, 57, 133, 0.16);
  transition: border .3s  ease, box-shadow .3s ease;
  justify-content: space-between;


  &--unlogged {

  }

  &--withoutCtaButton {

  }

  &__logo {
    background-image: url('../Header/logo.svg');
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    width: 94px;
    min-width: 94px;
    height: 20px;
    cursor: pointer;
    order: -3;
    min-height: $headerHeight;
  }

  &__project {
    font-weight: bold;
    order: -2;
    line-height: 59px;
    font-size: 15px;
  }

  &__burger {
    order: -1;
  }

  &__menu, &__right_menu {
    display: none;
  }

  &__profile {
    display: none;
    white-space: nowrap;
  }

  @media (max-width: ($responsiveBreakpoint - 1px)) {

    .menu-open & {

      flex-wrap: wrap;
      height: auto;
      max-height: 100vh;

      padding-left: 0;
      padding-right: 0;

      z-index: 1501; // DataTable Progress Indicator z-index = 1500

      text-align: center;
      .#{$uikitName}__menu-item {
        text-align: center;
        width: 100%;
      }

      &__logo {
        @include calc-size(margin-left, $spaceMd, $spaceLg, $mdBreakpoint, $xxlBreakpoint);
      }
      &__burger {
        @include calc-size(margin-right, $spaceMd, $spaceLg, $mdBreakpoint, $xxlBreakpoint);
      }

      &__menu, &__profile, &__right_menu {
        display: block;
      }

      &__project {
        min-height: $headerHeight;
        display: flex;
        align-items: center;
        font-size: 18px;
      }

      &__menu {
        flex: 0 0 100%;
        .#{$uikitName}__menu {
          height: auto;
        }
        &:before {
          content: '';
          display: block;
          box-shadow: 0 1px 2px 0 rgba(27, 57, 133, 0.5);
          border-top: 1px solid #f3f3f3;
          height: 1px;
          position: relative;
          top: -1px;
        }
      }

      &__profile {
        flex: 0 0 100%;
        border-top: 1px solid #f3f3f3;
        padding-left: 14px;
        padding-right: 14px;
        border-left-width: 0;
        border-right-width: 0;
      }

      &__right_menu {
        flex: 0 0 100%;
      }
    }
  }

  @media (min-width: $responsiveBreakpoint) {

    padding-right: 0;

    &__project {
      font-size: 18px;
    }

    &__logo {
      margin-right: 26px;
    }

    &__right_menu {
      .#{$uikitName}__menu-item:last-child {
        padding-left: 36px;
        padding-right: 36px;
      }
    }

    &__menu, &__profile, &__right_menu {
      display: block;
    }

    &__menu {
      flex-grow: 2;
    }

    &__burger {
      display: none;
    }
  }

}
