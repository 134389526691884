@import '../Core/variables.scss';
@import './MenuItem/style.scss';

.#{$uikitName}__menu {
  display: block;
  height: 60px;

  & > div {
    display: inline-block;
    text-align: center;
  }

  div:first-child {
    margin-left: 0;

    a {
      margin-left: 0;
    }
  }
}
