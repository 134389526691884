@import '../Core/variables.scss';

.error {
  .#{$uikitName}__select {
    border-bottom: solid 1px $red;
  }
}

.#{$uikitName}__select {
  display: flex;
  border-bottom: solid 1px $darkGray;
  border-radius: 0;
  font-size: 14px;

  select {
    max-width: 100%;
    color: $inputFontColor;
    font-weight: 300;
    padding: 0;
    line-height: 1.13;
    text-overflow: ellipsis;
    border: none;
    white-space: nowrap;
    overflow: hidden;
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSI3IiB2aWV3Qm94PSIwIDAgMTMgNyI+CiAgICA8cGF0aCBmaWxsPSJub25lIiBmaWxsLXJ1bGU9Im5vbnplcm8iIHN0cm9rZT0iIzRCQTgyRSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2Utd2lkdGg9IjEuNSIgZD0iTTEzIDBMNyA2IDEgMCIvPgo8L3N2Zz4K) no-repeat center right;
    cursor: pointer;
    outline: 0;
    flex: 1;
    position: relative;
    -webkit-appearance: none;
    height: 40px;
    max-height: 40px;
  }

  &--white {
    select {
      background-color: $inputBackgroundColor;
    }
  }

  &--grey {
    select {
      background-color: $inputBackgroundColor;
    }
  }

  label {
    margin-right: 10px;
    text-align: center;
    vertical-align: middle;
    line-height: 36px;
  }
}
