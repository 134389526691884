// UIkit name
$uikitName: "emankit";

// Fixed header height
$headerHeight: 60px;

// Breakpoints
$smBreakpoint: 576px;
$mdBreakpoint: 768px;
$lgBreakpoint: 992px;
$xlBreakpoint: 1200px;
$xxlBreakpoint: 1366px;
$headerBreakpoint: 1024px;

$responsiveBreakpoint: $lgBreakpoint;

// Space
$spaceSm: 10px;
$spaceMd: 15px;
$spaceLg: 26px;
$spaceXl: 30px;

// Skoda colors
$darkGray: #4a4a4a;
$lightGray: #cfd6dc;
$lighterGray: #6b7278;
$lightestGray: #e3eaf0;
$darkRed: #d20f22;
$lightGreen: #edf6ea;

  // Colors
$white: #ffffff;
$black: #13293d;
$darkBlack: #000000;
$silver: #cbcbcb;
$aqua: #00aacd;
$red: #e62336;
$green: #4ba82e;
$turquoise: #0f9ea2;
$orange: #e99100;

$cobalt: #1f3b80;
$cobalt-dark: darken( $cobalt, 7% );
$cobalt-light: rgba( $cobalt, .24 );

$verylightblue: #e4e7f1;
$verylightblue-dark: darken( $verylightblue, 10% );
$verylightblue-light: rgba( $verylightblue, .64 );

$red-light: mix($red, $white, 10%);

$aqua-light: rgba($aqua, 0.04);

// Peopleman color from palette
$grey-dark: mix($black, $white, 50%); // black color with opacity 50% = #89949e
$grey: mix($black, $white, 10%); // black color with opacity 10% = #e7eaec
$grey-light: mix($black, $white, 3%); // black color with opacity 3%
$blue: #0092E0;

// Fonts
$font-family: 'SKODANext', Arial, sans-serif;
$fontSize: 14px;
$smallFontSize: 12px;
$inputLabelFontSize: 16px;
$fontColor: $darkGray;

// Body background colors
$background-white: #ffffff;
$background-grey: #fbfbfc;
$background-darkGrey: #f9f9fc;
$background-sidebar: #fbfbfc;
$background-layout: #f2f2f2;

// Sidebar
$sidebarBorderColor: #ebebeb;

// Links
$linkColor: $green;

// Button styles
$buttonNormal: $green;
$buttonFontSize: 12px;
$buttonFontColor: $white;
$buttonShadowColor: rgba(75, 168, 46, 0.4);

$buttonHoverColor:  #2d8a10;
$buttonHoverShadowColor:  $buttonShadowColor;

$buttonActiveColor: $green;

$buttonDisabledColor: $lightGray;
$buttonDisabledFontColor: #a7aeb4;

$buttonActive: $cobalt;
$buttonActiveFontColor: $white;
$buttonActiveHoverColor: $cobalt-dark;

$buttonSecondaryBlueColor: rgba(0, 146, 224, 0.1);
$buttonSecondaryBlueFontColor: $blue;
$buttonSecondaryBlueHoverColor: rgba(0, 146, 224, 0.05);
$buttonSecondaryBlueActiveColor: rgba(0, 146, 224, 0.1);
$buttonSecondaryBlueDisabledColor: rgba(0, 146, 224, 0.05);
$buttonSecondaryBlueDisabledFontColor: rgba(0, 146, 224, 0.3);

$buttonSecondaryWhiteColor: $white;
$buttonSecondaryWhiteFontColor: #616161;
$buttonSecondaryWhiteHoverColor: $white;
$buttonSecondaryWhiteHoverFontColor: #616161;
$buttonSecondaryWhiteActiveBackgroundColor: #d8d8d8;

// Menu
$menuButtonColor: $darkBlack;
$menuButtonHoverColor: $cobalt;
$menuButtonHoverBackgroundColor: #f9f9fc;
$menuButtonActiveColor: $green;
$menuButtonActiveBorder: $cobalt;
$menuSubMenuActive: #edf0f2;

// Header menu
$menuLinkBorderColor: #dedede;
$menuLinkColor: #555b5f;

// Badges
$badgeGrayColor: $grey-dark;
$badgeGrayBorderColor: $grey-dark;
$badgeGrayFontColor: $white;

$badgeGreenColor: $green;
$badgeGreenBorderColor: $green;
$badgeGreenFontColor: $white;

$badgeOrangeColor: $orange;
$badgeOrangeBorderColor: $orange;
$badgeOrangeFontColor: $white;

$badgeRedColor: $red;
$badgeRedBorderColor: $red;
$badgeRedFontColor: $white;

$badgeLightBlueColor: $green;
$badgeLightBlueBorderColor: $green;
$badgeLightBlueFontColor: $white;

$badgeTurquoiseColor: $turquoise;
$badgeTurquoiseBorderColor: $turquoise;
$badgeTurquoiseFontColor: $white;

// Rows
$rowLineColor: $verylightblue;

// Modal
$modalDarkOverlayColor: rgba($black, 0.4);

// Sort select modal
$disabledSeparatorBorderColor: $verylightblue;

// Panel
$panelColor: $white;
$fontCollapsibleColor: #555b5f;
$fontCollapsibleErrorColor: #d00217;
$hoverCollapsibleColor: #f5f6f7;
$hoverCollapsibleArrowColor: #00aacd;

// Fieldset
$fontFieldsetColor: #a7aeb4;

// Indicator
$indicatorActiveColor: #59a80f;
$indicatorNonActiveColor: #d00217;

// Inputs
$inputBackgroundColor: $white;
$inputBorderColor: $silver;
$inputFontColor: $darkGray;
$inputPlaceholderColor: $darkGray;
$inputFocusBorderColor: $aqua;

$inputErrorBackgroundColor: #fcebed;
$inputErrorBorderColor: #e62336;

$inputGreyBackgroundColor: $grey-light;
$inputGreyFocusBackgroundColor: rgba(19, 41, 61, 0.08);

// Input disabled
$inputDisabledBackgroundColor: $white;
$inputDisabledColor: $grey-dark;
$inputDisabledBorderColor: $silver;

// Checkboxes
$checkboxBorderColor: #c6cacc;
$checkboxActiveColor: #59a80f;

// Tables
$infoThColor: $black;
$tableThColor: $white;
$tableThBorderColor: $grey;
$tableTdBorderColor: $grey;
$tableOddRowColor: $white;
$tableEvenRowColor: $grey-light;
$tableErrorRowColor: #fcebed;
$tableErrorRowWithSortColor: #f3e7e1;
$tableErrorFontColor: #d00217;
$tableHoverRowColor: #c6cacc;
$filterSeparatorColor: #c6cacc;
$activeFilterColor: #59a80f;
$tableThHoverColor: #c6cacc;
$tableThSortedHoverColor: #CDD9C4;
$opmTableBorder: #555b5f;
$tableHighlightRowColor: #f9f3c8;

// Table sorted
$tableSortedBgOdd: #F4F9EF;
$tableSortedBgEven: #EAF1E7;

// Notification bar
$notificationBarBorderColor: #c6cacc;

// Separator
$separatorColor: #c6cacc;

// Toast
$toastBackgroundColor: #ffffff;
$toastBorderColor: #cfd6dc;

// Alert
$alertBackgroundColor: rgba($red, .04);
$alertBorderColor: rgba($red, .08);
$alertFontColor: $red;

// Footer
$footerFontColor: #848484;

// Uploader
$uploaderColor: $white;
$uploaderBorderColor: $lightGray;

// Attachments
$attachmentBorderColor: #c6cacc;

// Tile
$tileTitleColor: #555b5f;
$tileTextColor: #000000;
$tileBorderColor: #c6cacc;
$tileShadowColor: rgba(109, 117, 120, 0.16);
$tileAfterLimitBorderColor: #a68730;
$tileAfterLimitBackgroundColor: #fffcf3;
$tileAfterLimitColor: #a68730;
$tilePurpleColor: #d05ad0;
$tileOrangeColor: #e97506;
$tileGreenColor: #59a80f;
$tileAzureColor: #14a7ff;
$tileRoyalColor: #0f15a8;
$tileScarletColor: #d00217;
