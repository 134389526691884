@import "../Core/variables";
@import '../../node_modules/react-day-picker/lib/style';

.#{$uikitName}__input_datepicker {
  background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNSIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE1IDE2Ij4KICAgIDxnIGZpbGw9IiM0QkE4MkUiIGZpbGwtcnVsZT0ibm9uemVybyI+CiAgICAgICAgPHBhdGggZD0iTTEzLjI0MiAxLjYwNWgtLjcwM1YuNDIxaC0xLjE3MnYxLjE4NEgzLjYzM1YuNDIxSDIuNDZ2MS4xODRoLS43MDNBMS43NyAxLjc3IDAgMCAwIDAgMy4zODJ2MTAuNDJjMCAuOTguNzg5IDEuNzc3IDEuNzU4IDEuNzc3aDExLjQ4NEExLjc3IDEuNzcgMCAwIDAgMTUgMTMuODAzVjMuMzgyYTEuNzcgMS43NyAwIDAgMC0xLjc1OC0xLjc3N3ptLjU4NiAxMi4xOThhLjU5LjU5IDAgMCAxLS41ODYuNTkySDEuNzU4YS41OS41OSAwIDAgMS0uNTg2LS41OTJWNS45ODdoMTIuNjU2djcuODE2em0wLTlIMS4xNzJWMy4zODJhLjU5LjU5IDAgMCAxIC41ODYtLjU5M2guNzAzdjEuMTg1aDEuMTcyVjIuNzg5aDcuNzM0djEuMTg1aDEuMTcyVjIuNzg5aC43MDNhLjU5LjU5IDAgMCAxIC41ODYuNTkzdjEuNDJ6Ii8+CiAgICAgICAgPHBhdGggZD0iTTIuNzkxIDcuMTE5aDEuMDQ3djEuMDU4SDIuNzkxek02Ljk3NyA3LjExOWgxLjA0N3YxLjA1OEg2Ljk3N3pNMTEuMTYzIDcuMTE5aDEuMDQ3djEuMDU4aC0xLjA0N3pNMi43OTEgOS41ODZoMS4wNDd2MS4wNThIMi43OTF6TTExLjE2MyA5LjU4NmgxLjA0N3YxLjA1OGgtMS4wNDd6TTYuOTc3IDkuNTg2aDEuMDQ3djEuMDU4SDYuOTc3ek0yLjc5MSAxMi4wNTRoMS4wNDd2MS4wNThIMi43OTF6TTYuOTc3IDEyLjA1NGgxLjA0N3YxLjA1OEg2Ljk3N3oiLz4KICAgIDwvZz4KPC9zdmc+Cg==) no-repeat center right;

  @extend .#{$uikitName}__input;

  &--white {
    @extend .#{$uikitName}__input--white;
  }

  &--grey {
    @extend .#{$uikitName}__input--grey;
  }
}

.error {
  .#{$uikitName}__input_datepicker {
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij4KICAgIDxnIGZpbGw9IiNFNjIzMzYiIGZpbGwtcnVsZT0ibm9uemVybyI+CiAgICAgICAgPHBhdGggZD0iTTEzLjI0MiAxLjYwNWgtLjcwM1YuNDIxaC0xLjE3MnYxLjE4NEgzLjYzM1YuNDIxSDIuNDZ2MS4xODRoLS43MDNBMS43NyAxLjc3IDAgMCAwIDAgMy4zODJ2MTAuNDJjMCAuOTguNzg5IDEuNzc3IDEuNzU4IDEuNzc3aDExLjQ4NEExLjc3IDEuNzcgMCAwIDAgMTUgMTMuODAzVjMuMzgyYTEuNzcgMS43NyAwIDAgMC0xLjc1OC0xLjc3N3ptLjU4NiAxMi4xOThhLjU5LjU5IDAgMCAxLS41ODYuNTkySDEuNzU4YS41OS41OSAwIDAgMS0uNTg2LS41OTJWNS45ODdoMTIuNjU2djcuODE2em0wLTlIMS4xNzJWMy4zODJhLjU5LjU5IDAgMCAxIC41ODYtLjU5M2guNzAzdjEuMTg1aDEuMTcyVjIuNzg5aDcuNzM0djEuMTg1aDEuMTcyVjIuNzg5aC43MDNhLjU5LjU5IDAgMCAxIC41ODYuNTkzdjEuNDJ6Ii8+CiAgICAgICAgPHBhdGggZD0iTTIuNzkxIDcuMTE5aDEuMDQ3djEuMDU4SDIuNzkxek02Ljk3NyA3LjExOWgxLjA0N3YxLjA1OEg2Ljk3N3pNMTEuMTYzIDcuMTE5aDEuMDQ3djEuMDU4aC0xLjA0N3pNMi43OTEgOS41ODZoMS4wNDd2MS4wNThIMi43OTF6TTExLjE2MyA5LjU4NmgxLjA0N3YxLjA1OGgtMS4wNDd6TTYuOTc3IDkuNTg2aDEuMDQ3djEuMDU4SDYuOTc3ek0yLjc5MSAxMi4wNTRoMS4wNDd2MS4wNThIMi43OTF6TTYuOTc3IDEyLjA1NGgxLjA0N3YxLjA1OEg2Ljk3N3oiLz4KICAgIDwvZz4KPC9zdmc+Cg==) no-repeat center right;
  }
}

.DayPickerInput-Overlay {
  top: 5px;
  border-radius: 4px;
  z-index: 15;
  box-shadow: 0 4px 16px 0 rgba(43, 43, 44, 0.16), 0 1px 4px 0 rgba(27, 57, 133, 0.08) !important;

  &:focus, & *:focus {
    outline: none;
  }
}

.DayPickerInput {
  width: 100%;
}

.DayPicker {
  padding: 16px;
}

.DayPicker-wrapper {
  padding-bottom: 0 !important;
}

.DayPicker-NavBar {

  height: 48px;
  margin-bottom: -48px;
  position: relative;

  .DayPicker-NavButton {
    @extend .#{$uikitName}__button;
    background: #edf6ea;
    position: absolute;
    top: 0;
    margin: 0;
    box-shadow: none;

    &:hover {
      opacity: 1;
      background: #deeed8;
      box-shadow: none;
    }

    &:active {
      background: #edf6ea;
      box-shadow: none;
    }

    &:before {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -7.5px;
      margin-left: -4px;
    }

    &.DayPicker-NavButton--prev {
      right: unset !important;
      left: 0 !important;

      &:before {
        width: 16px;
        height: 16px;
        content: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgZmlsbD0iIzRCQTgyRSIgZmlsbC1ydWxlPSJub256ZXJvIj4KICAgICAgICAgICAgPGc+CiAgICAgICAgICAgICAgICA8Zz4KICAgICAgICAgICAgICAgICAgICA8Zz4KICAgICAgICAgICAgICAgICAgICAgICAgPHBhdGggZD0iTTggMTIuMDcxTDIuNDI5IDYuNSAwLjkyOSA4IDggMTUuMDcxIDE1LjA3MSA4IDEzLjU3MSA2LjV6IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTAxNyAtMTcwKSB0cmFuc2xhdGUoNDUzIDE0MikgbWF0cml4KC0xIDAgMCAxIDU5MiAxNikgcm90YXRlKDkwIDggMjApIi8+CiAgICAgICAgICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0xLjUgNS41TDE0LjUgNS41IDE0LjUgNy41IDEuNSA3LjV6IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTAxNyAtMTcwKSB0cmFuc2xhdGUoNDUzIDE0MikgbWF0cml4KC0xIDAgMCAxIDU5MiAxNikgcm90YXRlKDkwIDggMjApIHJvdGF0ZSgtOTAgOCA2LjUpIi8+CiAgICAgICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4K) !important;
        transform: rotate(-180deg);
        left: 14px;
      }
    }

    &.DayPicker-NavButton--next {
      left: unset !important;
      right: 0 !important;

      &:before {
        width: 16px;
        height: 16px;
        content: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgZmlsbD0iIzRCQTgyRSIgZmlsbC1ydWxlPSJub256ZXJvIj4KICAgICAgICAgICAgPGc+CiAgICAgICAgICAgICAgICA8Zz4KICAgICAgICAgICAgICAgICAgICA8Zz4KICAgICAgICAgICAgICAgICAgICAgICAgPHBhdGggZD0iTTggMTIuMDcxTDIuNDI5IDYuNSAwLjkyOSA4IDggMTUuMDcxIDE1LjA3MSA4IDEzLjU3MSA2LjV6IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTAxNyAtMTcwKSB0cmFuc2xhdGUoNDUzIDE0MikgbWF0cml4KC0xIDAgMCAxIDU5MiAxNikgcm90YXRlKDkwIDggMjApIi8+CiAgICAgICAgICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0xLjUgNS41TDE0LjUgNS41IDE0LjUgNy41IDEuNSA3LjV6IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTAxNyAtMTcwKSB0cmFuc2xhdGUoNDUzIDE0MikgbWF0cml4KC0xIDAgMCAxIDU5MiAxNikgcm90YXRlKDkwIDggMjApIHJvdGF0ZSgtOTAgOCA2LjUpIi8+CiAgICAgICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4K) !important;

      }
    }
  }
}

.DayPicker-Month {
  margin: 0 !important;
}

.DayPicker-TodayButton {
  border-radius: 24px;
  box-shadow: 0 1px 4px 0 rgba($cobalt, 0.08);
  background: $buttonNormal;
  border-style: none;
  border-width: 0;
  color: $buttonFontColor;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  outline: 0;
  padding: 8px 20px;
  display: block;
  margin: auto;
}

.DayPicker-Week {
  .DayPicker-Day--selected.DayPicker-Day--outside:not(.DayPicker-Day--disabled) {
    &:hover {
      background-color: $cobalt-light;
      color: $cobalt;
      font-weight: bold;
    }
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background: $buttonNormal;

    &:hover {
      background: $buttonHoverColor;
      font-weight: bold;
    }
  }

  .DayPicker-Day--outside {
    color: rgba($grey, .56);
    font-weight: normal !important;
  }

  .DayPicker-Day--today {
    font-weight: bold !important;
  }

  .DayPicker-Day--today:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    color: $green;
  }

  .DayPicker-Day--today.DayPicker-Day--selected:not(.DayPicker-Day--outside) {
    color: $white !important;
  }
}

.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Week .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):hover {
  background-color: $grey !important;
  color: $green;
  font-weight: bold;
  cursor: pointer;
}


.DayPicker-Day, .DayPicker-Weekdays, .DayPicker-Caption, .DayPicker-Footer, .DayPickerInput-Overlay {
  line-height: initial;
}

.emankit__collapsible_container .header .DayPickerInput-Overlay {
  top: -20px;
}

.DayPicker-Caption {
  text-align: center !important;
  font-weight: 600;
  height: 40px;
  line-height: 40px;
  padding-left: 40px !important;
  padding-right: 40px !important;
  margin-bottom: 8px !important;
}

.DayPicker-Weekday, .DayPicker-Day {
  width: 40px;
  height: 40px;
  padding: 10px 11px !important;
  vertical-align: middle;
  font-size: ($fontSize - 1px) !important;
}

.DayPicker-Weekday {
  color: $black !important;
  font-weight: 700;
}

.DayPicker-Day {
  font-weight: normal;
  color: $black;
  border-radius: 20px;
}

.DayPicker-Day:not(.DayPicker-Day--selected) {
  transition: background-color .15s ease;
}

.DayPicker-wrapper .DayPicker-Footer {
  padding-top: 16px;
}

.DayPicker-Footer-Custom {
  margin-left: -16px;
  margin-right: -16px;
  border-top: 1px solid rgba($silver, .5);
  padding: 16px 16px 0;

  display: flex;
  justify-content: space-between;

  a {
    line-height: 38px;
    font-weight: bold;
  }

  .DayPicker-Range-Label {
    margin-left: auto;
    margin-right: 24px;
    font-size: ($fontSize - 1px);
    font-weight: normal;
    line-height: 38px;
    color: $grey;
  }
}
