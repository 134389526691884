@import '../Core/variables.scss';

.emankit__footer {
  margin-top: auto;
  color: $footerFontColor;
  line-height: 16px;
  font-weight: 300;
  // padding-top: 16px;
  // padding-bottom: 16px;
  font-size: 14px;
  text-align: center;

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    left: 0;
    // margin-top: -16px;
    border-top: 1px solid $verylightblue;
  }

  &__terms {
    color: $black;
    display: block;
  }

  &__links {
    .emankit__icon {
      margin-right: 4px;
    }
  }

  &__first_column {
    @media (min-width: $smBreakpoint) {
      text-align: center;
      order: 1;
    }
  }

  &__second_column {
    @media (min-width: $smBreakpoint) {
      text-align: right;
      order: 2;
    }
  }
}
