@import '../Core/variables.scss';

.emankit__pagination {
  position: relative;

  .buttons {
    text-align: right;
    position: static;
    @media (min-width: 1024px) {
      position: relative;
    }

    .stats {
      font-size: ($fontSize - 1px);
      line-height: 40px;
      text-align: center;

      width: 100%;
      position: absolute;
      left: 0;
      z-index: 0;

      @media (min-width: 1024px) {
        margin-right: 24px;
        position: static;
      }
    }

    .emankit__button {
      display: inline !important;
    }

    .page_buttons {
        display: inline;
        position: absolute;
        left: 50%;

      @media (min-width: 1024px) {
        position: initial;
        left: initial;
      }
    }

  }

  .pages {
    font-weight: normal;
    font-size: ($fontSize - 1px);
    align-items: center;
    display: flex !important;

    position: static;
    @media (min-width: 1024px) {
      position: relative;
    }

    .select {
      @extend .#{$uikitName}__select;
      width: 80px;
      display: inline-flex !important;

      @media (max-width: 1024px) {
        display: none !important;
      }
    }

    .label {
      display: none;
      @media (min-width: 1024px) {
        margin-left: 12px;
        display: inline-block;
      }
    }
  }

  button {
    &.page {
      display: none;
      @media (min-width: 1024px) {
        display: inline-block;
      }

      &:not(:hover) {
        transition: none;
      }
      &:not(.active) {
        background-color: inherit;
        box-shadow: none;
      }
    }

    &:last-child {
      margin-right: 0px;
    }

    &.previous_button {
      margin-right: 24px;
      color: $green;
      svg path {
        fill: $green !important;
      }

      &.disabled {
        color: $buttonDisabledFontColor;
        svg path {
          fill: $buttonDisabledFontColor !important;
        }
      }

      & > .emankit__icon {
        left: 7px;
        top: initial;
      }

      position: absolute;
      left: 0;

      @media (min-width: 1024px) {
        position: relative;
        left: auto;
      }
    }

    &.next_button {
      margin-left: 24px;
      color: $green;
      svg path {
        fill: $green !important;
      }

      &.disabled {
        color: $buttonDisabledFontColor;
        svg path {
          fill: $buttonDisabledFontColor !important;
        }
      }
      & > .emankit__icon {
        left: auto;
        right: 7px;
        top: initial;
      }
    }

    &.page {
      padding: 0 6px;

      &--active {
        color: $green;
      }

      &--other {
        color: $buttonDisabledFontColor;
      }
    }
    .emankit__icon {
      width: 24px;
      height: 24px;
      margin-top: -2px;
      transform: none;
    }
  }

  .gap {
    width: 24px;
    text-align: center;
    line-height: 40px;
    user-select: none;
    color: $buttonDisabledFontColor;
  }

  .per_page {
    color: $buttonDisabledFontColor;
    display: none;
    font-size: 15px;
    @media (min-width: 1024px) {
      display: inline-block;
    }
  }
}
