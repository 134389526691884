// https://google-webfonts-helper.herokuapp.com/fonts/muli?subsets=latin,latin-ext

/* SKODANext-regular - latin-ext_latin */
@font-face {
  font-family: 'SKODANext';
  font-style: normal;
  font-weight: 400;
  src: local('SKODANext Regular'), local('SKODANext-Regular'),
  url('./Fonts/SKODANext-Regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('./Fonts/SKODANext-Regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* SKODANext-600 - latin-ext_latin */
@font-face {
  font-family: 'SKODANext';
  font-style: normal;
  font-weight: 600;
  src: local('SKODANext Bold'), local('SKODANext-Bold'),
  url('./Fonts/SKODANext-Bold.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('./Fonts/SKODANext-Bold.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* SKODANext-700 - latin-ext_latin */
@font-face {
  font-family: 'SKODANext';
  font-style: normal;
  font-weight: 700;
  src: local('SKODABlack Black'), local('SKODANext-Black'),
  url('./Fonts/SKODANext-Black.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('./Fonts/SKODANext-Black.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
