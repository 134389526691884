@import '../Core/variables.scss';

.emankit__multiselect {
  .container {
    max-width: 100%;
    padding: 10px;
    color: #555b5f;
    line-height: 36px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    background-color: $white;
    border: 1px solid #c6cacc;
    box-shadow: 0 1px 1px 0 rgba(109, 117, 120, 0.16);
    cursor: pointer;
    outline: 0;
    flex: 1;
    border-radius: 4px;
    position: relative;
  }

  .searchInput {
    border: none;
    border-radius: 0px;
    border-bottom: 1px solid #c6cacc;
    padding: 8px 10px;
    &:hover {
      outline: none !important;
    }
    &:focus {
      border-bottom: 1px solid #c6cacc;
    }
  }

  .selector {
    @extend .container;
    padding: 0px 10px;
    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &Open {
      @extend .selector;
      border-radius: 4px 4px 0 0;
    }

    &.disabled {
      cursor: no-drop;
    }
  }

  .dropdownWrapper {
    position: relative;
    z-index: 999;
  }

  .dropdown {
    z-index: 1;
    position: absolute;
    padding: 5px 0px;
    background-color: white;
    border-radius: 0 0 4px 4px;
    border: 1px solid #c6cacc;
    border-top: none;
    width: 100%;
    max-height: 200px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .label {
    margin-right: 10px;
    text-align: center;
    vertical-align: middle;
    line-height: 36px;
  }

  .option {
    padding: 5px 10px;
    cursor: pointer;
    line-height: 24px;
    height: 34px;
    display: flex;
    justify-content: space-between;
    &:hover {
      background-color: #edf0f2;
    }

    div, label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: calc(100% - 36px);
    }

    span {
      width: 24px;
    }
  }

  .optionSelected {
    @extend .option;
    font-weight: 500;
  }

  .respect__input_checkbox {
    margin: 0px;
  }
}
