@import '../Core/variables.scss';
@import '../Core/mixins.scss';

@import './CheckBox/style.scss';
@import './Radio/style.scss';

.#{$uikitName}__input {
  display: block;
  border: none;
  border-bottom: solid 1px $darkGray;
  border-radius: 0;
  color: $inputFontColor;
  overflow: hidden;
  outline: none;
  @include calc-size(padding-top, 10px, 13px, $smBreakpoint, $xxlBreakpoint);
  @include calc-size(padding-bottom, 10px, 13px, $smBreakpoint, $xxlBreakpoint);
  // @include calc-size(padding-left, 10px, 16px, $smBreakpoint, $xxlBreakpoint);
  // @include calc-size(padding-right, 10px, 16px, $smBreakpoint, $xxlBreakpoint);
  padding-left: 0;
  padding-right: 0;
  font-weight: 300;
  font-family: $font-family;
  font-size: $fontSize;
  line-height: 13px;
  width: 100%;
  transition: border-color .3s, box-shadow .3s;

  min-height: 39px;
  height: 39px;

  &--white {
    background-color: transparent;
  }

  &--grey {
    background-color: transparent;
    &:focus {
      background-color: transparent;
    }
  }

  &--with_start_icon {
    padding-left: 40px;
  }

  &--with_stop_icon {
    // padding-right: 40px;
    background: none;
  }

  & + .#{$uikitName}__icon {
    path {
      transition: fill .3s;
      fill: $grey;
    }
  }

  &::placeholder {
    color: $inputPlaceholderColor;
  }

  &::-moz-placeholder {
    color: $inputPlaceholderColor;
  }

  &::-webkit-input-placeholder {
    color: $inputPlaceholderColor;
  }

  &:-ms-input-placeholder {
    color: $inputPlaceholderColor;
  }


  &:focus {
    box-shadow: 0 1px 0 0 rgba($inputBorderColor, .16);
  }

  &:disabled {
    cursor: no-drop;
    border-color: $inputDisabledBorderColor !important;
    color: $inputDisabledColor !important;

    & + .#{$uikitName}__icon, & + .#{$uikitName}__icon + .#{$uikitName}__icon {
      cursor: no-drop;
      path {
        fill: $inputDisabledColor !important;
      }
    }
  }

  @media (min-width: 1024px) {
    &.#{$uikitName}__input_big {
      font-size: ($fontSize + 2px);
      line-height: 24px;
      &.with_start_icon {
        padding: 19px 16px 19px 60px;
        & + .#{$uikitName}__icon.icon_start {
          top: 20px;
          left: 24px;
        }
      }
    }
  }

  // Do not display spinners with stop icon
  &[type=number] &--with_stop_icon {
    -moz-appearance:textfield;

    &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      display: none;
    }
  }

  &__container {
    position: relative;
    display: inline-block;
    width: 100%;

    .icon_start, .icon_stop {
      position: absolute;
      width: 24px;
      height: 24px;
      line-height: 24px;
      top: 8px;
    }

    .icon_start {
      left: 12px;
    }

    .icon_stop {
      right: 0px;
    }

    svg path {
      fill: black;
    }
  }
}
