@import '../Core/variables.scss';

.#{$uikitName}__employeecard {
  display: flex;

  &__containter {
    &--hasAvatar {
      padding-left: 30px;
    }
  }

  &__title {
    margin-top: 0px;
    margin-bottom: 5px;
  }

  &__degree {
    font-weight: 100;
    padding-right: 0.5em;
  }

  &__subtitle {
    color: $grey-dark;
  }

  &__avatar {
    border-radius: 50%;
  }
}
