.emankit__icon {
  vertical-align: middle;
  display: inline-block;

  &.emankit__icon_circle_fakefill {
    background-color: white;
    border-radius: 100px;
  }

  &:hover {
    cursor: pointer;
  }
}
