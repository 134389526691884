@import '../../Core/variables.scss';

.#{$uikitName}__menu-item {
  color: $menuButtonColor;
  cursor: pointer;
  font-size: ($fontSize + 2px);
  margin: 0;
  text-align: center;
  display: inline-block;
  transition: color .15s ease, background-color .15s ease, border .3s ease;
  padding: 14px 16px;
  font-style: normal;
  font-stretch: normal;
  line-height: 32px;
  white-space: nowrap;
  letter-spacing: normal;
  user-select: none;
  border-bottom: 3px solid transparent;

  &:hover {
    color: $menuButtonActiveColor;
    text-decoration: none;
  }

  &--active {
    border-bottom: 3px solid $menuButtonActiveColor;
  }

  &--submenu {
    position: relative;
    padding-right: 35px;

    &:hover > ul, &:focus > ul  {
      display: block;
    }

    li {

      a {
        display: block;
        width: 95%;
        margin-left: auto;
        margin-right: 0;
        margin-top: 16px;
        padding-bottom: 7px;
        padding-left: 0;
        padding-right: 0;
        div.emankit__badge {
          margin-left: 6px;
          line-height: 20px;
        }
      }
    }

    li.active {
      border-left: 4px solid;
      a {
        color: $cobalt;
      }
    }

    svg {
      position: absolute;
      top: 50%;
      margin-top: -2.5px;
      right: 16px;

      path {
        fill: $menuButtonColor;
      }
    }
  }

  &:hover & {
    &__submenu {
      display: block;
      background: $background-white;
      border-left: 4px solid transparent;
      transition: border .3s ease;
    }
  }


  &__submenu {
    display: none;
    position: absolute;
    list-style: none;
    min-width: 240px;
    z-index: 100;
    left: 0;
    margin-top: 14px;
    margin-bottom: 0;
    padding: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    box-shadow: 0 4px 16px 0 rgba(43, 43, 44, 0.16), 0 1px 4px 0 rgba(27, 57, 133, 0.08);

    li {
      text-align: left;

      &:before {
        content: none;
      }

      a {
        margin: 0;
        padding: 5px 16px;
        font-size: $fontSize;
        color: $grey-dark;
        display: inline-block;
        width: 100%;
        font-weight: normal;
        text-decoration: none;
        border-bottom: 1px solid $verylightblue-light;
        white-space: nowrap;

        &:hover {
          color: $blue;
        }
      }

      &:last-child {
        a {
          border-bottom: none;
        }
      }

    }

  }
}
