.range_select {

  &__from {
    &:after {
      content: "-";
      position: absolute;
      text-align: center;
      width: 30px;
      height: 20px;
      top: 50%;
      margin-top: -10px;
      right: -15px;
    }
  }

  &__to {
  }

  .emankit__input_div {
    width: 100%;
  }
}
