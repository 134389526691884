@import "variables";

/**
 * Table RESPECT layout
 */
.emankit__data_table {
  border-spacing: 0;
  width: 100%;

  &.coefficient_table {
    th, td {
      width: 25%;
    }
  }

  &.top_align {
    td, th {
      vertical-align: top;
    }
  }

  &.respect__statistics__table {
    tr, th {
      text-align: center;
    }

    th {
      border-top: 0px;
      border-bottom: 1px solid $tableTdBorderColor;
    }
  }

  &.with_hover {
    tbody tr:hover {
      td, th {
        background-color: $tableHoverRowColor !important;
      }
    }

    tbody tr.error:hover {
      td, th {
        background-color: darken($tableErrorRowColor, 5%);
      }
    }
  }

  &.clickable {
    tbody tr {
      cursor: pointer;
    }
  }

  tr.highlighted {
    td, th {
      background-color: $tableHighlightRowColor !important;
    }
  }

  tbody {
    .form-group {
      margin-bottom: 0px;
    }
  }

  &.smaller {
    thead, tbody {
      th, td {
        padding: 10px;

        .respect__input {
          padding: 5px;
        }
      }
    }
  }

  thead, tbody {
    background-color: $tableThColor;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    th {
      font-weight: bold;
      color: $fontColor;
      padding: 0px 6px;
      border-bottom: 1px solid $tableThBorderColor;
      border-right: 1px solid $tableTdBorderColor;
      text-align: left;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      &:last-child {
        border-right: 0px;
      }
    }
  }

  tbody {
    th {
      border-bottom: 1px solid $tableTdBorderColor;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    tr {
      height: 50px;
    }
  }

  tbody {
    &.small_cols td {
      padding: 7px;
    }

    td {
      border-right: 1px solid $tableTdBorderColor;
      padding: 8px 10px;
      white-space: nowrap;
      color: black;
      text-overflow: ellipsis;
      overflow: hidden;

      &.breakWords {
        word-break: break-word;
      }

      &:last-child {
        border-right: 0px;
      }
    }

    tr:nth-child(odd) {
      background-color: $tableOddRowColor;
    }

    tr:nth-child(even) {
      background-color: $tableEvenRowColor;
    }

    tr.error {
      background-color: $tableErrorRowColor;

      td {
        color: $tableErrorFontColor;
      }
    }
  }

  th, td {
    &.right {
      text-align: right;
    }
  }
}

.emankit_opm__table {
  .form-group {
    margin: 0 !important;
  }

  & > .col-md-6:first-child {
    padding-right: 0;
  }

  & > .col-md-6:last-child {
    padding-left: 0;
  }

  label.col-form-label, .col-form-input {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .col-form-input {
    background-color: $tableOddRowColor;
  }

  label.col-form-label {
    background-color: $tableThColor;
    font-weight: bold;
    border-right: 2px solid $opmTableBorder;
    text-align: left;
    padding-left: 30px;
  }
}

/**
 * Small table
 */
.emankit__texttable {
  table {
    width: 100%;
    border-spacing: 5px 1rem;
    margin: 20px 0 10px 0;
  }

  th {
    font-size: large;
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .error {
    color: red;
  }

  .center {
    text-align: center;
  }
}
