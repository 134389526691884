@import "variables.scss";
@import "reset.scss";
@import "fonts.scss";
@import "grid.scss";
@import "table.scss";
@import "utilities.scss";
@import "components.scss";
@import "mixins.scss";

* {
  font-family: $font-family;
}

html, body {
  width: 100%;
  height: 100%;
  font-size: $fontSize;
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  color: $fontColor;
}

body {
  background: $background-white;

  &.grey-background {
    background-color: $background-grey;

    .emankit__fixed_header {
      background-color: $background-grey;
    }

    .emankit__header_row--fixed {
      background-color: $background-grey;
    }
  }

  &.no-overflow {
    overflow-x: hidden;
  }

  &.opened-menu {
    overflow: hidden;

    @media only
    screen and (device-width : 414px) and (device-height : 896px) and (-webkit-device-pixel-ratio : 3),        // iPhone XR
    screen and (device-width : 375px) and (device-height : 812px) and (-webkit-device-pixel-ratio : 3),        // iPhone XS
    screen and (device-width : 414px) and (device-height : 896px) and (-webkit-device-pixel-ratio : 2),        // iPhone XS Max
    screen and (min-device-width : 375px) and (max-device-width : 812px) and (-webkit-device-pixel-ratio : 3), // iPhone X
    screen and (min-device-width : 375px) and (max-device-width : 667px),                                      // iPhone 6, 7, & 8
    screen and (min-device-width : 414px) and (max-device-width : 736px),                                      // iPhone 6, 7, & 8 Plus
    screen and (min-device-width : 320px) and (max-device-width : 568px),                                      // iPhone 5 & 5S
    screen and (min-device-width : 320px) and (max-device-width : 480px),                                      // iPhone 2G-4S
    screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait)         // iPad/mini portrait
    {
      height: calc(100vh - (64px + 80px + 75px)); // 75px = Safari Bottom Tollbar offset
    }
  }
}

h1 {
  @include calc-size(font-size, 26px, 32px, $smBreakpoint, $xxlBreakpoint);
  @include calc-size(margin-top, 18px, 25px, $smBreakpoint, $xxlBreakpoint);
  @include calc-size(margin-bottom, 8px, 10px, $smBreakpoint, $xxlBreakpoint);
}
h2 {
  @include calc-size(font-size, 24px, 28px, $smBreakpoint, $xxlBreakpoint);
  @include calc-size(margin-top, 15px, 20px, $smBreakpoint, $xxlBreakpoint);
  @include calc-size(margin-bottom, 15px, 20px, $smBreakpoint, $xxlBreakpoint);
}
h3 {
  @include calc-size(font-size, 20px, 24px, $smBreakpoint, $xxlBreakpoint);
  @include calc-size(margin-top, 12px, 18px, $smBreakpoint, $xxlBreakpoint);
  @include calc-size(margin-bottom, 12px, 18px, $smBreakpoint, $xxlBreakpoint);
}
h4 {
  font-size: 18px;
  @include calc-size(margin-top, 10px, 15px, $smBreakpoint, $xxlBreakpoint);
  @include calc-size(margin-bottom, 10px, 15px, $smBreakpoint, $xxlBreakpoint);
}
h5 {
  font-size: 16px;
  margin: 0 0 18px;
}
h6 {
  font-size: 14px;
  margin: 0 0 15px;
}

a {
  color: $linkColor;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

p {
  line-height: 24px;
}

ol, ul {
  list-style: none;
  padding-left: 1em;
  li {
    margin: 6px 0;
    &::before {
      display: inline-block;
      width: 1.5em;
      margin-left: -1em;
    }
  }
}

ol {
  counter-reset: item;
  li::before {
    content: counter(item) ". ";
    counter-increment: item;
    color: $grey;
  }
}

ul {
  li::before {
    content: "\2013";
    transform: scale(1.5);
    position: relative;
    top: -2px;
    left: 5px;
  }

  &.with_line {
    border-left: 5px solid $blue;

    li {
      font-size: 18px;
      font-weight: bold;
      color: $blue;
      margin-bottom: 10px;

      &:before {
        content: "";
        display: none;
      }
    }
  }
}

#root {
  width: 100%;
  height: 100%;
}


@keyframes rotateSpinner {
  0%    {transform: rotate(0);      stroke-dashoffset: 185px; }
  50%   {transform: rotate(1turn);  stroke-dashoffset: 325px; }
  100%  {transform: rotate(3turn);  stroke-dashoffset: 185px; }
}
@keyframes rotate {
  0%    {transform: rotate(0);    }
  100%  {transform: rotate(-1turn);}
}
