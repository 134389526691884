@import '../Core/variables.scss';
@import '../Core/mixins.scss';

.label_wrapper {
  display: inline-block;
  width: 96%;
}

.tooltip_wrapper {
  display: inline-block; 
  width: 3%; 
  margin-left: 1%;
}

.respect__input_container {
  position: relative;

  label {
    color: $darkGray;
    font-weight: 900;
  }

  span.optional {
    margin-left: 5px;
  }

  span.required {
    margin-left: 5px;
    color: $blue;
  }

  .emankit__label_tooltip {
    .emankit__icon {
      margin-top: -2px;
      cursor: help;
    }
  }

  &.checkbox_arrays {
    .respect__input_checkbox_label {
      width: 30%;
      margin-bottom: 15px;
    }
  }

  &.error {
    input, select, textarea {
      border-color: $inputErrorBorderColor;
    }
  }

  &.disabled {
    input, select, textarea {
      cursor: no-drop;

      // Has precedence
      border-color: $inputDisabledBorderColor;
      background-color: $inputDisabledBackgroundColor;
      color: $inputDisabledColor;
    }

    label {
      color: $grey !important;
      cursor: no-drop;
    }

    svg path {
      fill: $inputDisabledColor;
    }
  }

  .error_message {
    position: absolute;
    right: 16px;
    color: $inputErrorBorderColor;
    padding: 7px 0;
    padding-left: 16px;
    font-weight: 300;
    font-size: $smallFontSize;
    text-align: right;
  }

  .hint_message {
    color: $menuLinkColor;
    font-style: italic;
  }

  // This is used for "non-editable" items in forms
  .form-fake-input-div, .form-fake-label {
    line-height: 32px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .col-form-label {
    margin-bottom: 0;
    font-size: $fontSize;
    vertical-align: top;
    line-height: 32px;

    @media (min-width: 576px) {
      text-align: right;
    }

    &.col-sm-12 {
      text-align: left;
    }
  }
}

.form-group {
  @include calc-size(margin-bottom, 10px, 15px, $mdBreakpoint, $xxlBreakpoint);
}

select {
  line-height: 46px;
  height: 46px;
}
