@import '../Core/variables.scss';
@import '../Core/mixins.scss';

.#{$uikitName}__columns {
  border-radius: 4px;
  position: relative;

  @media (min-width: $mdBreakpoint) {
    &:before, &:after {
      content: "";
      position: absolute;
      top: 0;
      height: 100%;
    }

    &--cols_2 {
      &:before {
        left: 50%;
      }

      &:after {
        display: none;
      }
    }

    &--cols_3 {
      &:before {
        left: 33.3%;
      }

      &:after {
        left: 66.6%;
      }
    }
  }
}
