@import '../Core/variables.scss';

.respect__fieldset {
  position: relative;
  margin: 0;
  padding: 0;
  border: none;
  word-break: break-word;

  legend {
    display: block;
    position: relative;
    font-size: 12px;
    font-weight: normal;
    margin: 0 0 5px;
    color: $fontFieldsetColor;
  }

}

