@import "../Core/variables.scss";

.emankit__uploader {
  width: 100%;
  margin-bottom: 32px;

  .dropzone {
    outline: none;
    width: 100%;
    border: solid 1px $uploaderBorderColor;
    background-color: rgba(227, 234, 240, 0.37);
    text-align: center;
    padding: 40px;

    .text {
      font-size: $fontSize;
      font-weight: 300;
      line-height: 0.93;
      color: $darkGray;
      margin-top: 0;
      margin-bottom: 5px;
    }

    .upload_image {
      margin-bottom: 12px;
    }

    .browse {
      font-weight: bold;
      color: $green;
    }
  }
}
