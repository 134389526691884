@import '../Core/variables.scss';

.emankit__filter_bar__item {
  // position: relative;

  label {
    color: $grey-dark;
  }

  .fake_input {
    border-bottom: 1px solid #4d545a;
    color: $black;
    line-height: 40px;
    height: 40px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    cursor: pointer;
    font-weight: normal;

    &--withIcon {
      padding-right: 30px;
    }

    svg {
      position: absolute;
      right: 0;
      top: 15px;
    }

    .placeholder {
      color: $inputPlaceholderColor;
    }
  }

  .input_container {
    input {
      border-radius: 4px !important;
      border: solid 1px #cbcbcb !important;
      padding: 0px 4px !important;
    }

    input::placeholder {
      color: #7b7b7c !important;
    }

    .#{$uikitName}__input_checkbox__label {
      display: flex;
      margin: 0px !important;
      padding: 0px 4px !important;
      align-items: center;
      .#{$uikitName}__input_checkbox {
        position: initial !important;
        background-color: transparent !important;
      }

      label {
        margin: 0px !important;
        padding: 0px 0px 0px 4px !important;
      }
    }
  }

  .dropdown {
    background-color: white;
    position: absolute;
    min-width: 220px;
    max-width: fit-content;
    z-index: 801;
    margin-top: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.4);

    input[type=text] {
      @extend .#{$uikitName}__input;
      @extend .#{$uikitName}__input--grey;
      width: 200px !important;
      margin: 10px;
      outline: 0;
    }

    label {
      display: block;
      margin: 5px 10px 0;
    }

    hr {
      margin: 0;
      padding: 0;
      border: 0;
      border-bottom: 1px solid #c6cacc;
    }

    .inputs {
      height: 210px;
      overflow: auto;

      & > strong {
        line-height: 33px;
        padding-left: 24px;
      }

      label {
        display: flex;
        align-items: center;
        min-height: 32px;
        cursor: pointer;
        padding-left: 10px;

        &.option {
          font-weight: normal;
          display: flex;
          flex-flow: row;

          .text {
            display: block;
            white-space: normal;
            max-width: 350px;
          }
        }

        input { margin-right: 10px; }

        &:hover {
          background-color: #edf0f2;
        }
      }
    }

    .buttons {
      text-align: right;
      padding: 10px;
    }
  }
}
