@import '../Core/variables.scss';

.emankit__overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: $modalDarkOverlayColor;
  display: flex;
  z-index: 999;
  flex-direction: column;

  &.clean_bg {
    background: initial;
  }
}

.emankit__modal_open {
  overflow: visible;
}

.emankit__modal {
  width: 344px;
  position: relative;
  background: rgb(255, 255, 255);
  margin: auto;
  box-shadow: 0 4px 16px 0 rgba(43, 43, 44, 0.16), 0 1px 4px 0 rgba(27, 57, 133, 0.08);
  overflow-x: hidden;
  overflow-y: visible;
  max-width: 100%;
  max-height: 100%;

  &__container {
    display: flex;
    flex-flow: column;
    max-height: 100%;
  }

  &__header {
    position: relative;
    font-size: 18px;
    font-weight: bold;
    line-height: 25px;
    padding: $spaceLg $spaceXl;
    color: $darkGray;

    .close_button {
      position: absolute;
      right: -18px;
      top: 20%;
      margin-top: -18px;

      > .emankit__button.only_icon {
        border-radius: 0;
        background-color: unset;
        box-shadow: unset;
        color: $black;

        .emankit__icon {
          path {
            fill: $black;
          }
        }
      }
    }
  }

  &.with_padding {
    .emankit__modal__body {
      padding: 20px;
    }
  }

  &.with_small_padding {
    .emankit__modal__body {
      padding: 10px;
    }
  }

  &__body {
    padding: 0 $spaceXl;
    max-height: 600px;

    & > div > *:last-child {
      margin-bottom: 0;
    }
  }

  &.default_overflow  .emankit__modal__body {
    overflow-x: visible !important;
    overflow-y: visible !important;
  }

  &__footer {
    padding: $spaceLg $spaceXl;
    line-height: 60px;

    .buttons {
      text-align: right;

      button {
        margin-left: 10px;
      }
    }
  }

  &.visible_overflow {
    overflow: visible !important;
    .emankit__modal__body {
      overflow: visible;
    }
  }
}
