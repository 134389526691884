@import '../Core/variables.scss';

.#{$uikitName}__select_with_search {
  &--white & {
    &__control {
      background-color: transparent !important;
    }
  }

  &--grey & {
    &__control {
      background-color: transparent !important;
    }
  }

  &__control {
    min-height: 40px !important;
    border-radius: 20px !important;
    box-shadow: 0 1px 1px 0 rgba(109,117,120,.16) !important;
    border: none !important;

    &--is-focused {
      border: 1px solid $inputFocusBorderColor !important;
      box-shadow: none !important;
    }
  }

  &__indicator {
    color: $black !important;
    padding-right: 15px !important;

    svg {
      width: 18px;
    }
  }

  &__value-container {
    font-weight: bold;
    padding-left: 20px !important;
  }

  &__indicator-separator {
    display: none;
  }

  &__option {
    &--is-selected {
      background: $blue !important;
    }
  }
}
