@import '../Core/variables.scss';

.emankit__badge {
  position: relative;
  padding: 0 6px;
  display: inline-block;
  border-width: 1px;
  margin: -1px;
  border-style: solid;
  border-radius: 4px;

  span {
    text-align: center;
    font-weight: bold;
    min-width: 10px;
    font-size: ($fontSize - 1px);
    line-height: 22px;
    display: inline-block;
    white-space: nowrap;
  }

  &.count {
    border-radius: 24px;
    span {
      font-size: ($fontSize - 2px);
    }
  }
}
