@import '../Core/variables.scss';

.emankit__button {
  background: $buttonNormal;
  border-radius: 30px;
  box-shadow: 0 4px 8px 0 $buttonShadowColor;
  border-style: none;
  border-width: 0;
  color: $buttonFontColor;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 1.71px;
  outline: 0;
  padding: 12px 10px;
  cursor: pointer;
  position: relative;
  min-height: 40px;
  font-size: $buttonFontSize;
  user-select: none;
  white-space: nowrap;
  text-transform: uppercase;
  padding: 0px 20px;

  svg {
    margin-right: 8px;
    margin-top: -2px;
    path {
      fill: $buttonFontColor;
    }
  }

  &.only_icon {
    span.empty_text {
      visibility: hidden;
      width: 0;
    }
  }

  &.big {
    svg {
      width: 15px !important;
      height: 15px !important;
    }
  }

  span {
    line-height: 20px;
    font-size: $buttonFontSize;
    height: 20px;
    display: inline-block;
  }

  &:hover {
    background-color: $buttonHoverColor;
    box-shadow: 0 4px 8px 0 $buttonHoverShadowColor;
  }

  &:active {
    background: $buttonActiveColor;
    box-shadow: none;
  }

  &.disabled {
    cursor: not-allowed;
    background: $buttonDisabledColor !important;
    color: $buttonDisabledFontColor !important;
    box-shadow: none;

    svg path {
      fill: $buttonDisabledFontColor !important;
    }
  }

  &.secondary_blue {
    background: $buttonSecondaryBlueColor;
    color: $buttonSecondaryBlueFontColor;
    box-shadow: none;

    svg path {
      fill: $buttonSecondaryBlueFontColor;
    }

    &:hover {
      background: $buttonSecondaryBlueHoverColor;
    }

    &:active {
      background: $buttonSecondaryBlueActiveColor;
    }

    &.disabled {
      color: $buttonSecondaryBlueDisabledFontColor !important;
      background: $buttonSecondaryBlueDisabledColor !important;
      opacity: 1 !important;

      svg path {
        fill: $buttonSecondaryBlueDisabledFontColor !important;
      }
    }
  }

  &.active_blue {
    background: #003c65;
    color: $white;
    box-shadow: none;

    svg path {
      fill: $white;
    }

    &:hover {
      background: #025289;
    }

    &:active {
      background: #003355;
    }

    &.disabled {
      cursor: not-allowed;
      background: $buttonDisabledColor !important;
      color: $buttonDisabledFontColor !important;
      box-shadow: none;

      svg path {
        fill: $buttonDisabledFontColor !important;
      }
    }
  }

  &.basic {
    border: none;
    background: none;
    color: $lighterGray;
    box-shadow: none;

    &.disabled {
      background: none !important;
    }
  }

  &.secondary_white {
    background: $buttonSecondaryWhiteColor;
    color: $buttonSecondaryWhiteFontColor;
    box-shadow: none;
    border-radius: 23px;
    border: solid 1.5px $lightGray;

    svg path {
      fill: $buttonSecondaryWhiteFontColor;
    }

    &:hover {
      border-color: $darkGray !important;
      svg path {
        fill: $buttonSecondaryWhiteHoverFontColor !important;
      }
    }

    &:active {
      background: $buttonSecondaryWhiteActiveBackgroundColor;
      border-color: $buttonSecondaryWhiteActiveBackgroundColor !important;
    }

    &.disabled {
      color: $lightGray !important;
      background: $buttonSecondaryWhiteColor !important;

      svg path {
        fill: $lightGray !important;
      }
    }

  }

  &.dangerous {
    background: $red;
    color: white;
    box-shadow: 0 4px 8px 0 rgba(230, 35, 54, 0.4);

    &:hover, &:active {
      background: $darkRed !important;
    }
  }

  &.secondary_dangerous {
    background: white;
    color: $lighterGray;
    border: solid 1.5px $red;
    box-shadow: none;

    svg path {
      fill: $red !important;
    }

    &:hover, &:active {
      border-color: $darkRed !important;

      svg path {
        fill: $darkRed !important;
      }
    }

    &.disabled {
      color: $lightestGray !important;
      border-color: $lightestGray !important;
      background: white !important;

      svg path {
        fill: $lightestGray !important;
      }
    }
  }

  &.small {
    min-height: 30px;
    height: 30px;
    padding: 0 15px;

    svg {
      top: 6px;
      transform: scale(0.8);
    }

    &.with_icon_left {
      padding-left: 30px;

      svg {
        left: 18px;
      }
    }

    &.with_icon_right {
      padding-right: 30px;

      svg {
        right: 18px;
      }
    }

    span {
      font-size: $buttonFontSize - 2;
    }
  }

  &.only_icon {
    svg {
      left: 50%;
      transform: translate(-50%, 0);
      top: 16px;
      width: 12px;
      height: 12px;
    }
    &.small {
      svg {
        left: 50%;
        transform: translate(-50%, 0) scale(0.8);
        top: 10px;
      }
    }
  }
}
